<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '修改审批' : '新增审批'}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%">
      <el-form class="businessList-add-wrapper" ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <!-- <el-col :span="24">
            <el-form-item :label="$t('wrapperCol')" prop="wrapperCol">
              <el-input clearable v-model="form.leaveType"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_title')" prop="title">
              <el-input clearable v-model="form.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_money')" prop="money">
              <el-input clearable type='number' v-model.number="form.money" onkeyup="this.form.money=this.form.money.replace(/[^\d.]/g,'');" >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_startDate')" prop="wrapperCol_startDate">
              <el-input clearable v-model="form.startDate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_duration')" prop="wrapperCol_duration">
              <el-input clearable v-model="form.duration"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item :label="$t('wrapperCol_description')" prop="description">
              <el-input clearable type="textarea" rows="3" v-model="form.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  components: {
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
          if (valid) {
            this.$http.post(this.isEdit ? apis.MaterialInfo_UpdateAsync : apis.leaveSave, {
              ...this.form,
              "type": "1",
              "buildTime": ["2020-04-30T02:36:59.582Z", "2020-05-01T02:36:59.582Z"],
              "duration": "24.00",
              "description": "请假一天",
              "key": "leave",
              "procDefId": "qingjia:1:acf0d8e1d69e426ba2e03728c66f0ed2",
              "procName": "请假流程测试cjw",
              "startDate": "2020-04-30 10:36:00",
              "endDate": "2020-05-01 10:36:00",
            }).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
                this.$message.success("保存成功！");
              }
            })
          }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
        // 在这里获取回显的数据
        this.form.procDefId = data;
        // this.$http.get(`${apis.leave}/${data.businessKey}`).then((res) => {
        //   if (res.data.code === 0) {
        //     this.form = { ...res.data.data }
        //     console.log("getDataQuery -> res.data.data", res.data.data, this.form)
        //   }
        // })
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
