/*
 * @Author: genwai
 * @Date: 2020-04-21 16:59:32
 * @LastEditTime: 2020-04-24 11:33:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\activiti\ModelList\modelClass.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.title = null
      this.status = null
      this.result = null
    }
  }
}

export default searchFrom
