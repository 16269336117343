<!--
 * @Author: your name
 * @Date: 2020-04-26 11:20:22
 * @LastEditTime: 2020-04-27 16:03:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\act\businessList\dialog\my-application.vue
 -->
<template>
  <div class="my-application-wrapper">
    <el-dialog
      title="新增"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%">
      <div class="application-content">
        <el-card shadow="hover" @click.native="onItemApplication(item)" class="application-item" v-for="(item, index) in profList" :key="item.id">
          <div class="name">{{index + 1}}.{{item.name}}</div>
          <div class="version">版本：v{{item.version}}</div>
        </el-card>
      </div>
    </el-dialog>
     <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
  </div>
</template>

<script>
import apis from '@/apis'
import mixin from '@/mixins/dialogMixin'
import addDialog from './addDialog.vue'

export default {
  mixins: [mixin],
  data() {
    return {
      profList: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
    }
  },
  //部件
  components: {
    addDialog,
  },
  //静态
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {},
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    changeAddDialog(dialogStatus) {
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    onItemApplication(item) {
      this.addDialog.visible = true
      this.$nextTick(() => {
        this.$refs.addDialog.getDataQuery(item.id);
      });
    },
    closeDialog() {
      this.$emit("closeDialog", false);
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery() {
        // 在这里获取回显的数据
      this.$http.get(apis.profAllList).then((res) => {
        if (res.data.code === 0) {
          this.profList = res.data.rows
        }
      })
    },
  },
  //请求数据
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.application-content {
  padding-bottom: 30px;
  min-height: 250px;
  .application-item {
    width: 230px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
    .name {
      color: #1890ff;
      line-height: 1.5;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }
    .verson {
      color: rgba(0,0,0,.45);
      font-size: 14px;
    }
  }
}
</style>
