<!--
 * @Author: 陈剑伟
 * @Date: 2020-04-21 16:44:17
 * @LastEditTime: 2020-04-27 16:42:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\activiti\ModelList\index.vue
 -->
<template>
  <div class="model-wrapper">
    <div class="search-container">
       <el-form ref="form" :model="form" label-width="80px">
          <el-row>
            <el-col :span="6">
                <el-form-item label="申请标题">
                  <el-input clearable v-model="form.title"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="状态">
                  <el-select class="status" v-model="form.status" filterable clearable style="width:100%">
                    <el-option :value="item.value" :label="item.label" v-for="item in statusMap" :key="item.value"></el-option>
                </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="审批结果">
                  <el-select class="result" v-model="form.result" filterable clearable style="width:100%">
                    <el-option :value="item.value" :label="item.label" v-for="item in resultMap" :key="item.value"></el-option>
                </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" class="text-right">
                <el-button type="primary" @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
                <el-button type="primary" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
            </el-col>
          </el-row>
       </el-form>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
         <el-table-column
            type="index"
            align="center"
            label="序号"
            width="55">
          </el-table-column>
				<el-table-column
					type="selection"
          align="center"
          :selectable="onSelectable"
					width="55">
				</el-table-column>
				<el-table-column
					prop="title"
          align="center"
					label="申请标题"
					min-width="180">
				</el-table-column>
				<el-table-column
					prop="procName"
          align="center"
					label="所属流程"
					width="130">
				</el-table-column>
				<el-table-column
					prop="currentTask"
          align="center"
					label="当前审批环节"
					min-width="140">
				</el-table-column>
				<el-table-column
					prop="result"
          align="center"
					label="审批结果"
					min-width="120">
          <template slot-scope="scope">
            <span>{{scope.row.result | resultFilter}}</span>
          </template>
				</el-table-column>
				<el-table-column
					prop="status"
          align="center"
					label="状态"
					width="120">
          <template slot-scope="scope">
            <span>{{scope.row.status | statusFilter}}</span>
          </template>
				</el-table-column>
				<el-table-column
					prop="applyTime"
          align="center"
					label="申请时间"
					min-width="220">
				</el-table-column>
        <el-table-column
          align="center"
          width="300px"
					:label="$t('option')">
          <template slot-scope="scope">
            <!-- <el-button class="default-button" @click="onModelListEdit(scope.row.id)">详情</el-button> -->
            <el-button v-if="buttonAuth.includes('act:businessList:cancle')" size="mini" type="primary" round :disabled="scope.row.status!==1" @click="openDeleteDialog(scope.row.procInstId, 11)">撤销</el-button>
            <el-button v-if="buttonAuth.includes('act:businessList:remove')" size="mini" round type="primary" :disabled="scope.row.status===1" @click="openDeleteDialog(scope.row.id, 1)">{{$t('delete')}}</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-wrapper">
      <div class="">
        <el-button size="mini" round type="primary"  @click="handleAdd()" v-if="buttonAuth.includes('act:businessList:save')">{{$t('add')}}</el-button>
        <el-button size="mini" round type="primary" :disabled="selectedArr.length===0" v-if="buttonAuth.includes('act:businessList:remove')"  @click="openDeleteDialog(selectedArr.map(item => item.procInstId).join(','))">{{$t('delete')}}</el-button>
      </div>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
    <!-- 新增修改 -->
    <my-application ref="myApplication" :Language="Language" :show="myApplication.visible" @closeDialog="changeAddDialog"></my-application>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import ModelSearchClass from "./modelSearchClass";
import apis from '@/apis'
import mixin from '@/mixins/index'
import addDialog from './dialog/addDialog.vue'
import confirmDialog from '@/components/confirmDialog.vue'
import myApplication from "./dialog/my-application.vue";

export default {
  data() {
    return {
      form: new ModelSearchClass('form'),
      statusMap: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "处理中",
          value: "1",
        },
        {
          label: "结束",
          value: "2",
        },
        {
          label: "撤销",
          value: "3",
        },
        {
          label: "中止",
          value: "4",
        },
      ],
      resultMap: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "处理中",
          value: "1",
        },
        {
          label: "通过",
          value: "2",
        },
        {
          label: "驳回",
          value: "3",
        },
        {
          label: "撤销",
          value: "4",
        },
        {
          label: "删除",
          value: "5",
        },
        {
          label: "中止",
          value: "6",
        },
      ],
      myApplication: {
        visible: false,
        isEdit: false,
      },
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      tableData: [],
      modelerUrl: "",
      modelerLoading: "",
      modelList: {
        visible: false,
      },
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        '1': '处理中',
        '2': '结束',
        '3': '撤销',
        '4': '中止',
      }
      return statusMap[status]
    },
    resultFilter(status) {
      const statusMap = {
        '1': '处理中',
        '2': '通过',
        '3': '驳回',
        '4': '撤销',
        '5': '删除',
        '6': '中止',
      }
      return statusMap[status]
    },
  },
  mixins: [mixin],
  //部件
  components: {
    addDialog,
    confirmDialog,
    myApplication,
  },
  //静态
  props: {},
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {
    ...mapState(['Language']),
  },
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["addDialog"].getDataQuery(data);
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.myApplication.visible = dialogStatus;
      });
    },
    // 选择框是否警用
    onSelectable(row) {
      if (row.status === 1) {
        return false;
      }
      return true;
    },
    onModelListEdit() {
      this.modelList.visible = true
    },
    handleAdd() {
      this.myApplication.visible = true
      this.$nextTick(() => {
        this.$refs.myApplication.getDataQuery()
      })
    },
    /**
     * 新增弹窗
     */
    handleClose() {
      this.modelList.visible = false
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 1) {
        this.modelDelete()
      } else {
        this.modelPublish()
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type
      this.confirmDialog.data.id = id
      this.changeConfirmDialog(true, type)
    },
    /**
     * 删除
     */
    modelDelete() {
      this.$http.post(`${apis.businessRemove}?ids=${this.confirmDialog.data.id}`).then((res) => {
      if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("删除成功!")
          this.searchData()
        }
      })
    },
    /**
     * 撤销
     */
    modelPublish() {
      this.$http.post(`${apis.processInsRemove}?ids=${this.confirmDialog.data.id}&reason=主动撤销`).then((res) => {
      if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("撤销成功!")
          this.searchData()
        }
      })
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.get(apis.business, {
        params: this.form,
        headers: { current_id: this.$store.state.USERID },
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new ModelSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
  },
  //请求数据
  created() {
    console.log("create", process.env)
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.search-container {
  margin-top: 20px;
}
.page-wrapper {
  margin-top: 20px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.text-right {
  text-align: right;
}
</style>
