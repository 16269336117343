/*
 * @Author: your name
 * @Date: 2020-04-24 11:50:15
 * @LastEditTime: 2020-04-27 16:11:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\act\businessList\dialog\addDialogClass.js
 */
import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
  zh,
  en,
}
class searchFrom {
  constructor(type, lanuage = 0) {
    if (type === 'form') {
      this.brand = ''
      this.money = ''
      this.description = ''
      this.procDefId = ''
      this.key = 'leave'
      this.title = ''
    } else if (type === 'rule') {
      this.title = [
        {
          required: true,
          message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_title,
          trigger: 'blur',
        },
      ]
      this.money = [
        {
          required: true,
          message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_money,
          trigger: 'blur',
        },
      ]
      this.description = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_description,
          trigger: 'blur',
        },
      ]
      this.unit = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_unit,
          trigger: 'blur',
        },
      ]
      this.purchasePrice = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_purchasePrice,
          trigger: 'blur',
        },
      ]
      this.supplierId = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_supplierId,
          trigger: 'change',
        },
      ]
      this.serialNumFlag = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_serialNumFlag,
          trigger: 'change',
        },
      ]
      this.categoryId = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_categoryId,
          trigger: 'change',
        },
      ]
      this.brand = [
        {
          required: true,
          message: Lang[lanuage === 1 ? 'en' : 'zh'].ple_ent_brand,
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
